<template>
  <div>
    <Header
      class="flex"
      :back="true"
      :text="$t('Views.Book.book')"
      :route="routeBack"
      :title="type"
    >
      <h5 class="header-title">
        {{ type }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card>
        <WeeklyDateSelector
          :week-length="WeekState.weekLength"
          :week-start="WeekState.date"
          :booking-notifications="bookingNotifications"
          @date-selected="dateChanged"
          @week-changed="weekChanged"
        />
      </Card>
      <Card
        v-if="loaded"
        position="bottom medium-small-padding"
      >
        <div class="filter-resource_container">
          <FilterButton
            :show-text="!hasFilters.length && !amountFilters.length"
            @click="goToFilter()"
          />
          <FilterItem
            v-for="(filter, index) in amountFilters"
            :key="index"
            :title="`${filter} ${index}`"
            @close="removeFromAmountFilterList(index)"
          />
          <FilterItem
            v-for="(filter, index) in hasFilters"
            :key="index"
            :title="filter"
            @close="removeFromHasFilterList(filter)"
          />
          <FilterItem
            v-if="hasValidTimeFilter"
            :title="getHHMM"
            @close="resetFromUntil()"
          />
        </div>
        <div v-if="resources.length">
          <div
            v-for="resource in resources"
            :key="resource.id"
          >
            <Resource
              class="margin margin-top margin-medium-large"
              :resource="resource"
            />
          </div>
        </div>
        <div
          v-else
          class="margin margin-top margin-medium"
        >
          <h6 class="normal-semi">
            {{ $t('Views.Book.no_match') }}
          </h6>
          <p class="small-regular">
            {{ $t('Views.Book.no_match_resource') }}
          </p>
        </div>
      </Card>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-expressions */
import WeekState from '@/singletons/week.state.singleton';
import WeeklyDateSelector from '@/components/weeklydateselector/weekly.dateselector.vue';
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import UserService from '@/Services/User/user.service';
import ZoneState from '@/singletons/zone.state.singleton';
import QueryMixin from '@/mixins/query.mixin';
import BookingService from '@/Services/Bookings/bookings.service';
import { createHasString, createAmountString } from '@/functions/filter.query.functions';
import { sortResourcesByStatus, sortResourcesByName } from '@/functions/resources.functions';

import ExtendedDate from '@/classes/extended.date.class';
import { getBookingNotifications } from '@/functions/date.notification.functions';
import UserState from '@/singletons/user.state.singleton';
import Resource from './components/resource.item.vue';
import FilterButton from './components/filter.button.vue';
import FilterItem from './components/filter.item.vue';

const HALF_HOUR_MILLISECONDS = 1800000;

export default {
  components: {
    Resource,
    WeeklyDateSelector,
    FilterItem,
    FilterButton,
    Card,
    Header,
  },
  mixins: [QueryMixin],
  data() {
    return {
      WeekState,
      loaded: false,
      bookingNotifications: [],
    };
  },
  computed: {
    getHHMM() {
      const { from, until, date } = this.$route.query;

      /* If today and from filter is less then current time,
      then from filter should the current time in milliseconds from midnight
      (in whole 30 mins, floor) */

      const today = new ExtendedDate();
      const msSinceMidnight = today.getTime() - today.setHours(0, 0, 0, 0);
      const msSinceMidnightPretty = msSinceMidnight - (msSinceMidnight % HALF_HOUR_MILLISECONDS);

      const isToday = new ExtendedDate(date).isToday();
      const startTime = isToday && Number(from) <= Number(msSinceMidnightPretty) ? msSinceMidnightPretty : Number(from);

      const start = new ExtendedDate().setHours(0, 0, 0, 0) + (startTime);
      const end = new ExtendedDate().setHours(0, 0, 0, 0) + (Number(until));
      return `${new ExtendedDate(start).localeTimeString()} - ${new ExtendedDate(end).localeTimeString()}`;
    },
    hasValidTimeFilter() {
      const { from } = this.$route.query;
      const { until } = this.$route.query;
      return (from && until && from !== until);
    },
    resources() {
      // console.log(ZoneState.activeResources);
      return sortResourcesByStatus(sortResourcesByName(ZoneState.activeResources));
    },
    hasFilters() {
      return ZoneState.activeHasEquipmentFilters;
    },
    amountFilters() {
      return ZoneState.activeAmountEquipmentFilters;
    },
    type() {
      return ZoneState.resourcesOfActiveType[0].TypeName || ZoneState.resourceTypeFilter;
    },
    routeBack() {
      const { date } = this.$route.query;
      return `/book?date=${date}`;
    },
  },
  mounted() {
    this.updateQuery({ date: WeekState.date.getDateString() });
    const { date } = this.$route.query;
    const checkedDate = date && date !== 'undefined' ? new ExtendedDate(date) : new ExtendedDate();
    this.weekChanged(checkedDate);
    this.loaded = true;
  },
  methods: {
    resetFromUntil() {
      this.updateQuery({ from: 0, until: 0 });
    },
    goToFilter() {
      this.$router.push({ name: 'booking-filters', query: this.$route.query });
    },
    removeFromHasFilterList(filter) {
      const shallowCopy = [...this.hasFilters];
      const index = shallowCopy.indexOf(filter);
      shallowCopy.splice(index, 1);
      // Modifying the url will modify activeHasEquipmentFilters: See router.service.js
      this.hasFilters.length ? this.replaceQuery({ has: createHasString(shallowCopy) }) : this.removeQuery('has');
    },
    removeFromAmountFilterList(key) {
      const shallowCopy = { ...this.amountFilters };
      delete shallowCopy[key];
      // Modifying the url will modify activeAmountEquipmentFilters: See router.service.js
      Object.entries(shallowCopy).length > 0 ? this.replaceQuery({ amount: createAmountString(shallowCopy) }) : this.removeQuery('amount');
    },
    async weekChanged(date) {
      WeekState.changeWeek(date);
      this.updateQuery({ date: WeekState.date.getDateString() });
      this.bookingNotifications = [];
      await Promise.all([
        UserService.fetchUserBookings(WeekState.weekStart.getTime(), WeekState.weekEnd.getTime()).then(() => {
          this.bookingNotifications = getBookingNotifications(UserState.bookings, WeekState.weekLength);
        }),
        BookingService.getBookingsOfDay(date),
      ]);
    },
    dateChanged(date) {
      WeekState.date = date;
      this.updateQuery({ date: WeekState.date.getDateString() });
    },
  },
};
</script>

<style lang="scss" scoped>

.filter-resource_container{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-direction: row-reverse;
}
</style>
