<template>
  <div
    class="filter-item"
  >
    <p> {{ title }} </p>
    <div
      class="icon-close_container"
      @click="$emit('close')"
    >
      <svg-icon
        src="/icons/close_icon.svg"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.filter-item{
  background-color: var(--primary-color-light);
  display: flex;
  padding: 0.5rem 0.75rem;
  gap:0.5rem;
  border-radius: 0.5rem;
  p{
    color: var(--primary-color);
    margin: auto
  }
  svg{
    size: 1.2rem;
    height: 1.2rem;
    width: 1.2rem;
    vertical-align: text-top;
    fill: var(--primary-color);
    color: var(--primary-color);
    &:hover{
      cursor: pointer;
    }
  }
}
</style>
