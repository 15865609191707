<template>
  <div
    class="resource-header_container"
  >
    <div
      class="flex left-side overflow-hidden"
      @click="goToResource()"
    >
      <RoundResourceIcon
        :src="resource.Icon || '/icons/default_resource.svg'"
        :type="stateString(false)"
      />
      <div class="overflow-hidden margin margin-right margin-medium">
        <h6 class="normal-semi elipsis">
          {{ resource.Name }}
        </h6>
        <p class="small-medium">
          {{ stateString(true) }}
        </p>
      </div>
    </div>
    <div
      v-if="tiedToAFloor"
      class="primary-color-icon noselect"
      @click="addQueryZid()"
    >
      <svg-icon
        src="/icons/map_navigation_pin.svg"
      />
    </div>
  </div>
</template>

<script>

import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import ZoneState from '@/singletons/zone.state.singleton';
import UserState from '@/singletons/user.state.singleton';
import QueryMixin from '@/mixins/query.mixin';
import PhoneService from '@/Services/Phone/phone.service';
import mapController from '@/classes/map/map.controller';
import MapState from '../../../singletons/map.state.singleton';

export default {
  components: {
    RoundResourceIcon,
  },
  mixins: [QueryMixin],
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      states: ZoneState.ZONE_STATE,
    };
  },
  computed: {
    stateString() {
      return (i18n) => this.getStateString(i18n);
    },
    status() {
      return ZoneState.zoneStates[this.resource.Zid]?.Status;
    },
    tiedToAFloor() {
      // console.log(MapState.floorplanGroup.children[0].children.find((z) => z.name === this.resource.Zid));
      return !!Object.keys(MapState.planElements).find((id) => Number(id) === this.resource.Zid);
    },
  },
  methods: {
    addQueryZid() {
      if (PhoneService.isInPhoneApp()) {
        this.$router.push({ name: 'map', query: { zid: this.resource.Zid } });
      } else {
        this.updateQuery({ zid: this.resource.Zid });
        mapController.showMapMarker(this.resource, true);
        if (UserState.user?.AbstractUser) {
          MapState.selectedZone = this.resource;
        }
      }
    },
    goToResource() {
      if (!PhoneService.isInPhoneApp()) {
        mapController.showMapMarker(this.resource, true);
      }
      if (UserState.user?.AbstractUser) {
        this.$router.push(`/navigator/book/${this.resource.Type}/${this.resource.Zid}?ignoreType=false`);
      } else {
        this.$router.push(`/book/${this.resource.Type}/${this.resource.Zid}?ignoreType=false`);
      }
    },
    getStateString(i18n) {
      switch (this.status) {
        case this.states.UNKNOWN:
          return i18n ? this.$t('Views.Book.available') : 'unkown';
        case this.states.AVAILABLE:
          return i18n ? this.$t('Views.Book.available') : 'available';
        case this.states.AWAY:
          return i18n ? this.$t('Views.Book.booked') : 'away';
        case this.states.OCCUPIED:
          return i18n ? this.$t('Views.Book.occupied') : 'occupied';
        default:
          return i18n ? this.$t('Views.Book.available') : 'unkown';
      }
    },
  },
};
</script>

<style lang="scss">
.resource-header_container{
  display: flex;
  justify-content: space-between;
  &:hover{
    cursor: pointer;
  }
  .left-side{
    flex: 1 1 auto;
    gap: 1rem;
    h6,p{
      margin: auto 0;
    }
  }
  .primary-color-icon{
    margin: auto 0;
    svg{
      fill: var(--primary-color);
      vertical-align: top;
    }
  }
}
</style>
